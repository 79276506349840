import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Dog, DogImage, Pedigree, PedigreeNameRecord } from '../models/dog.model';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { ModalService } from '../services/modal.service';
import { environment } from '../../environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { PopperContent } from 'ngx-popper/src/popper-content';
import domToImage from 'dom-to-image';
import jsPDF, { RGBAData } from 'jspdf';

declare var LeaderLine: any;

@Component({
  selector: 'app-pedigree',
  templateUrl: './pedigree.component.html',
  styleUrls: ['./pedigree.component.scss']
})
export class PedigreeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('Sire', {read: ElementRef}) sire: ElementRef | undefined;
  @ViewChild('SireSire', {read: ElementRef}) sireSire: ElementRef | undefined;
  @ViewChild('SireSireSire', {read: ElementRef}) sireSireSire: ElementRef | undefined;
  @ViewChild('SireSireDam', {read: ElementRef}) sireSireDam: ElementRef | undefined;
  @ViewChild('SireDam', {read: ElementRef}) sireDam: ElementRef | undefined;
  @ViewChild('SireDamSire', {read: ElementRef}) sireDamSire: ElementRef | undefined;
  @ViewChild('SireDamDam', {read: ElementRef}) sireDamDam: ElementRef | undefined;
  @ViewChild('Dam', {read: ElementRef}) dam: ElementRef | undefined;
  @ViewChild('DamSire', {read: ElementRef}) damSire: ElementRef | undefined;
  @ViewChild('DamSireSire', {read: ElementRef}) damSireSire: ElementRef | undefined;
  @ViewChild('DamSireDam', {read: ElementRef}) damSireDam: ElementRef | undefined;
  @ViewChild('DamDam', {read: ElementRef}) damDam: ElementRef | undefined;
  @ViewChild('DamDamSire', {read: ElementRef}) damDamSire: ElementRef | undefined;
  @ViewChild('DamDamDam', {read: ElementRef}) damDamDam: ElementRef | undefined;

  @ViewChild('SireSireSireSire', {read: ElementRef}) sireSireSireSire: ElementRef | undefined;
  @ViewChild('SireSireSireDam', {read: ElementRef}) sireSireSireDam: ElementRef | undefined;
  @ViewChild('SireSireDamSire', {read: ElementRef}) sireSireDamSire: ElementRef | undefined;
  @ViewChild('SireSireDamDam', {read: ElementRef}) sireSireDamDam: ElementRef | undefined;
  @ViewChild('SireDamSireSire', {read: ElementRef}) sireDamSireSire: ElementRef | undefined;
  @ViewChild('SireDamSireDam', {read: ElementRef}) sireDamSireDam: ElementRef | undefined;
  @ViewChild('SireDamDamSire', {read: ElementRef}) sireDamDamSire: ElementRef | undefined;
  @ViewChild('SireDamDamDam', {read: ElementRef}) sireDamDamDam: ElementRef | undefined;
  @ViewChild('DamSireSireSire', {read: ElementRef}) damSireSireSire: ElementRef | undefined;
  @ViewChild('DamSireSireDam', {read: ElementRef}) damSireSireDam: ElementRef | undefined;
  @ViewChild('DamSireDamSire', {read: ElementRef}) damSireDamSire: ElementRef | undefined;
  @ViewChild('DamSireDamDam', {read: ElementRef}) damSireDamDam: ElementRef | undefined;
  @ViewChild('DamDamSireSire', {read: ElementRef}) damDamSireSire: ElementRef | undefined;
  @ViewChild('DamDamSireDam', {read: ElementRef}) damDamSireDam: ElementRef | undefined;
  @ViewChild('DamDamDamSire', {read: ElementRef}) damDamDamSire: ElementRef | undefined;
  @ViewChild('DamDamDamDam', {read: ElementRef}) damDamDamDam: ElementRef | undefined;

  @ViewChild('dogName', {read: ElementRef}) dog: ElementRef | undefined;
  @ViewChild('tooltipcontent') tooltipcontent: PopperContent | undefined;
  @ViewChild('failedtooltipcontent') failedtooltipcontent: PopperContent | undefined;
  @ViewChild('pedigreeExportView', {static: false}) pedigreeExportView: ElementRef | undefined;

  dogCallName = '';
  // pedigree names:
  dogRegName = 'Unknown';
  dogSireName = 'Unknown';
  dogDamName = 'Unknown';
  dogSireSireName = 'Unknown';
  dogSireDamName = 'Unknown';
  dogSireSireSireName = 'Unknown';
  dogSireSireDamName = 'Unknown';
  dogSireDamSireName = 'Unknown';
  dogSireDamDamName = 'Unknown';
  dogDamSireName = 'Unknown';
  dogDamDamName = 'Unknown';
  dogDamSireSireName = 'Unknown';
  dogDamSireDamName = 'Unknown';
  dogDamDamSireName = 'Unknown';
  dogDamDamDamName = 'Unknown';
  dogRegNameFull = 'Unknown';
  dogSireNameFull = 'Unknown';
  dogDamNameFull = 'Unknown';
  dogSireSireNameFull = 'Unknown';
  dogSireDamNameFull = 'Unknown';
  dogSireSireSireNameFull = 'Unknown';
  dogSireSireDamNameFull = 'Unknown';
  dogSireDamSireNameFull = 'Unknown';
  dogSireDamDamNameFull = 'Unknown';
  dogDamSireNameFull = 'Unknown';
  dogDamDamNameFull = 'Unknown';
  dogDamSireSireNameFull = 'Unknown';
  dogDamSireDamNameFull = 'Unknown';
  dogDamDamSireNameFull = 'Unknown';
  dogDamDamDamNameFull = 'Unknown';
  dogSireSireSireSireName = 'Unknown';
  dogSireSireSireDamName = 'Unknown';
  dogSireSireDamSireName = 'Unknown';
  dogSireSireDamDamName = 'Unknown';
  dogSireDamSireSireName = 'Unknown';
  dogSireDamSireDamName = 'Unknown';
  dogSireDamDamSireName = 'Unknown';
  dogSireDamDamDamName = 'Unknown';
  dogDamSireSireSireName = 'Unknown';
  dogDamSireSireDamName = 'Unknown';
  dogDamSireDamSireName = 'Unknown';
  dogDamSireDamDamName = 'Unknown';
  dogDamDamSireSireName = 'Unknown';
  dogDamDamSireDamName = 'Unknown';
  dogDamDamDamSireName = 'Unknown';
  dogDamDamDamDamName = 'Unknown';

  dogRegHealth: string[] = [];
  dogSireHealth: string[] = [];
  dogDamHealth: string[] = [];
  dogSireSireHealth: string[] = [];
  dogSireDamHealth: string[] = [];
  dogSireSireSireHealth: string[] = [];
  dogSireSireDamHealth: string[] = [];
  dogSireDamSireHealth: string[] = [];
  dogSireDamDamHealth: string[] = [];
  dogDamSireHealth: string[] = [];
  dogDamDamHealth: string[] = [];
  dogDamSireSireHealth: string[] = [];
  dogDamSireDamHealth: string[] = [];
  dogDamDamSireHealth: string[] = [];
  dogDamDamDamHealth: string[] = [];

  sireId = 0;
  damId = 0;
  sireSireId = 0
  sireDamId = 0;
  sireSireSireId = 0;
  sireSireDamId = 0;
  sireDamSireId = 0;
  sireDamDamId = 0;
  damSireId = 0;
  damDamId = 0;
  damSireSireId = 0;
  damSireDamId = 0;
  damDamSireId = 0;
  damDamDamId = 0;
  sireSireSireSireId = 0;
  sireSireSireDamId = 0;
  sireSireDamSireId = 0;
  sireSireDamDamId = 0;
  sireDamSireSireId = 0;
  sireDamSireDamId = 0;
  sireDamDamSireId = 0;
  sireDamDamDamId = 0;
  damSireSireSireId = 0;
  damSireSireDamId = 0;
  damSireDamSireId = 0;
  damSireDamDamId = 0;
  damDamSireSireId = 0;
  damDamSireDamId = 0;
  damDamDamSireId = 0;
  damDamDamDamId = 0;
  line1: any | undefined;
  line2: any | undefined;
  line3: any | undefined;
  line4: any | undefined;
  line5: any | undefined;
  line6: any | undefined;
  line7: any | undefined;
  line8: any | undefined;
  line9: any | undefined;
  line10: any | undefined;
  line11: any | undefined;
  line12: any | undefined;
  line13: any | undefined;
  line14: any | undefined;
  line15: any | undefined;
  line16: any | undefined;
  line17: any | undefined;
  line18: any | undefined;
  line19: any | undefined;
  line20: any | undefined;
  line21: any | undefined;
  line22: any | undefined;
  line23: any | undefined;
  line24: any | undefined;
  line25: any | undefined;
  line26: any | undefined;
  line27: any | undefined;
  line28: any | undefined;
  line29: any | undefined;
  line30: any | undefined;
  image: DogImage | undefined;
  _dog: Dog | undefined;
  _pedigree: Pedigree | undefined;
  missingPedigreeDataExists = false;
  todaysDate = DateTime.now().toLocaleString();

  @Input() summary: boolean = false;
  @Input('dog')
  set in(val: Dog) {
    this._dog = val;
    this.dogCoatColor = this._dog?.coat_color?.coat_color_description ?? 'Unknown';
    const datenumeric = this._dog?.date_of_birth ?? '1/1/1970';
    const dateDate = DateTime.fromISO(datenumeric);
    this.dogDOB = dateDate.toUTC().toISODate() ?? 'Unknown';
    this.dogDOBPub = dateDate.toUTC().toFormat('MM/dd/yyyy')
    this.dogGender = this._dog?.sex == 'M' ? 'Male' : 'Female' ?? 'Unknown';
  }

  @Input('pedigree')
  set in2(pedigree: Pedigree) {
    this.sireId = 0;
    this.damId = 0;
    this.sireSireId = 0
    this.sireDamId = 0;
    this.sireSireSireId = 0;
    this.sireSireSireSireId = 0;
    this.sireSireSireDamId = 0;
    this.sireSireDamId = 0;
    this.sireDamSireId = 0;
    this.sireDamDamId = 0;
    this.damSireId = 0;
    this.damDamId = 0;
    this.damSireSireId = 0;
    this.damSireDamId = 0;
    this.damDamSireId = 0;
    this.damDamDamId = 0;
    this.sireSireSireSireId = 0;
    this.sireSireSireDamId = 0;
    this.sireSireDamSireId = 0;
    this.sireSireDamDamId = 0;
    this.sireDamSireSireId = 0;
    this.sireDamSireDamId = 0;
    this.sireDamDamSireId = 0;
    this.sireDamDamDamId = 0;
    this.damSireSireSireId = 0;
    this.damSireSireDamId = 0;
    this.damSireDamSireId = 0;
    this.damSireDamDamId = 0;
    this.damDamSireSireId = 0;
    this.damDamSireDamId = 0;
    this.damDamDamSireId = 0;
    this.damDamDamDamId = 0;
    this._pedigree = pedigree;

    this.dogCallName = '';
    // pedigree names:
    this.dogRegName = 'Unknown';
    this.dogSireName = 'Unknown';
    this.dogDamName = 'Unknown';
    this.dogSireSireName = 'Unknown';
    this.dogSireDamName = 'Unknown';
    this.dogSireSireSireName = 'Unknown';
    this.dogSireSireDamName = 'Unknown';
    this.dogSireDamSireName = 'Unknown';
    this.dogSireDamDamName = 'Unknown';
    this.dogDamSireName = 'Unknown';
    this.dogDamDamName = 'Unknown';
    this.dogDamSireSireName = 'Unknown';
    this.dogDamSireDamName = 'Unknown';
    this.dogDamDamSireName = 'Unknown';
    this.dogDamDamDamName = 'Unknown';
    this.dogRegNameFull = 'Unknown';
    this.dogSireNameFull = 'Unknown';
    this.dogDamNameFull = 'Unknown';
    this.dogSireSireNameFull = 'Unknown';
    this.dogSireDamNameFull = 'Unknown';
    this.dogSireSireSireNameFull = 'Unknown';
    this.dogSireSireDamNameFull = 'Unknown';
    this.dogSireDamSireNameFull = 'Unknown';
    this.dogSireDamDamNameFull = 'Unknown';
    this.dogDamSireNameFull = 'Unknown';
    this.dogDamDamNameFull = 'Unknown';
    this.dogDamSireSireNameFull = 'Unknown';
    this.dogDamSireDamNameFull = 'Unknown';
    this.dogDamDamSireNameFull = 'Unknown';
    this.dogDamDamDamNameFull = 'Unknown';
    this.dogSireSireSireSireName = 'Unknown';
    this.dogSireSireSireDamName = 'Unknown';
    this.dogSireSireDamSireName = 'Unknown';
    this.dogSireSireDamDamName = 'Unknown';
    this.dogSireDamSireSireName = 'Unknown';
    this.dogSireDamSireDamName = 'Unknown';
    this.dogSireDamDamSireName = 'Unknown';
    this.dogSireDamDamDamName = 'Unknown';
    this.dogDamSireSireSireName = 'Unknown';
    this.dogDamSireSireDamName = 'Unknown';
    this.dogDamSireDamSireName = 'Unknown';
    this.dogDamSireDamDamName = 'Unknown';
    this.dogDamDamSireSireName = 'Unknown';
    this.dogDamDamSireDamName = 'Unknown';
    this.dogDamDamDamSireName = 'Unknown';
    this.dogDamDamDamDamName = 'Unknown';

    this.dogRegHealth = [];
    this.dogSireHealth = [];
    this.dogDamHealth = [];
    this.dogSireSireHealth = [];
    this.dogSireDamHealth = [];
    this.dogSireSireSireHealth = [];
    this.dogSireSireDamHealth = [];
    this.dogSireDamSireHealth = [];
    this.dogSireDamDamHealth = [];
    this.dogDamSireHealth = [];
    this.dogDamDamHealth = [];
    this.dogDamSireSireHealth = [];
    this.dogDamSireDamHealth = [];
    this.dogDamDamSireHealth = [];
    this.dogDamDamDamHealth = [];
    if ((pedigree != null) && (pedigree.dog != null)) {
      const dogRecord = pedigree.dog;
      this.dogRegNameFull = this.createDogName(dogRecord.name, dogRecord.health, dogRecord.callName);
      this.dogRegName = dogRecord.name.titleString;
      this.dogRegHealth = dogRecord.healthPub.split(' ');
      this.dogCallName = dogRecord.callName;
      if (dogRecord.hasOwnProperty('sire')) {
        const dogSireRecord = dogRecord.sire;
        this.dogSireNameFull = this.createDogName(dogSireRecord.name, dogSireRecord.health, dogSireRecord.callName);
        this.dogSireName = dogSireRecord.name.titleString;
        this.dogSireHealth = dogSireRecord.healthPub.split(' ');
        this.sireId = dogSireRecord.dog_id;
        if (dogSireRecord.hasOwnProperty('sire')) {
          const dogSireSireRecord = dogSireRecord.sire;
          this.dogSireSireNameFull = this.createDogName(dogSireSireRecord.name, dogSireSireRecord.health, dogSireSireRecord.callName);
          this.dogSireSireName = dogSireSireRecord.name.titleString;
          this.dogSireSireHealth = dogSireSireRecord.healthPub.split(' ');
          this.sireSireId = dogSireSireRecord.dog_id;
          if (dogSireSireRecord.hasOwnProperty('sire')) {
            const dogSireSireSireRecord = dogSireSireRecord.sire;
            this.dogSireSireSireNameFull = this.createDogName(dogSireSireSireRecord.name, dogSireSireSireRecord.health, dogSireSireSireRecord.callName);
            this.dogSireSireSireName = dogSireSireSireRecord.name.titleString;
            this.dogSireSireSireHealth = dogSireSireSireRecord.healthPub.split(' ');
            this.sireSireSireId = dogSireSireSireRecord.dog_id;
            if (dogSireSireSireRecord.hasOwnProperty('sire')) {
              const dogSireSireSireSireRecord = dogSireSireSireRecord.sire;
              this.dogSireSireSireSireName = this.createDogName(dogSireSireSireSireRecord.name, dogSireSireSireSireRecord.health, dogSireSireSireSireRecord.callName);
              this.sireSireSireSireId = dogSireSireSireSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogSireSireSireRecord.hasOwnProperty('dam')) {
              const dogSireSireSireDamRecord = dogSireSireSireRecord.dam;
              this.dogSireSireSireDamName = this.createDogName(dogSireSireSireDamRecord.name, dogSireSireSireDamRecord.health, dogSireSireSireDamRecord.callName);
              this.sireSireSireDamId = dogSireSireSireDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
          if (dogSireSireRecord.hasOwnProperty('dam')) {
            const dogSireSireDamRecord = dogSireSireRecord.dam;
            this.dogSireSireDamNameFull = this.createDogName(dogSireSireDamRecord.name, dogSireSireDamRecord.health, dogSireSireDamRecord.callName);
            this.dogSireSireDamName = dogSireSireDamRecord.name.titleString;
            this.dogSireSireDamHealth = dogSireSireDamRecord.healthPub.split(' ');
            this.sireSireDamId = dogSireSireDamRecord.dog_id;
            if (dogSireSireDamRecord.hasOwnProperty('sire')) {
              const dogSireSireDamSireRecord = dogSireSireDamRecord.sire;
              this.dogSireSireDamSireName = this.createDogName(dogSireSireDamSireRecord.name, dogSireSireDamSireRecord.health, dogSireSireDamSireRecord.callName);
              this.sireSireDamSireId = dogSireSireDamSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogSireSireDamRecord.hasOwnProperty('dam')) {
              const dogSireSireDamDamRecord = dogSireSireDamRecord.dam;
              this.dogSireSireDamDamName = this.createDogName(dogSireSireDamDamRecord.name, dogSireSireDamDamRecord.health, dogSireSireDamDamRecord.callName);
              this.sireSireDamDamId = dogSireSireDamDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
        } else {
          this.missingPedigreeDataExists = true;
        }
        if (dogSireRecord.hasOwnProperty('dam')) {
          const dogSireDamRecord = dogSireRecord.dam;
          this.dogSireDamNameFull = this.createDogName(dogSireDamRecord.name, dogSireDamRecord.health, dogSireDamRecord.callName);
          this.dogSireDamName = dogSireDamRecord.name.titleString;
          this.dogSireDamHealth = dogSireDamRecord.healthPub.split(' ');
          this.sireDamId = dogSireDamRecord.dog_id;
          if (dogSireDamRecord.hasOwnProperty('sire')) {
            const dogSireDamSireRecord = dogSireDamRecord.sire;
            this.dogSireDamSireNameFull = this.createDogName(dogSireDamSireRecord.name, dogSireDamSireRecord.health, dogSireDamSireRecord.callName);
            this.dogSireDamSireName = dogSireDamSireRecord.name.titleString;
            this.dogSireDamSireHealth = dogSireDamSireRecord.healthPub.split(' ');
            this.sireDamSireId = dogSireDamSireRecord.dog_id;
            if (dogSireDamSireRecord.hasOwnProperty('sire')) {
              const dogSireDamSireSireRecord = dogSireDamSireRecord.sire;
              this.dogSireDamSireSireName = this.createDogName(dogSireDamSireSireRecord.name, dogSireDamSireSireRecord.health, dogSireDamSireSireRecord.callName);
              this.sireDamSireSireId = dogSireDamSireSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogSireDamSireRecord.hasOwnProperty('dam')) {
              const dogSireDamSireDamRecord = dogSireDamSireRecord.dam;
              this.dogSireDamSireDamName = this.createDogName(dogSireDamSireDamRecord.name, dogSireDamSireDamRecord.health, dogSireDamSireDamRecord.callName);
              this.sireDamSireDamId = dogSireDamSireDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
          if (dogSireDamRecord.hasOwnProperty('dam')) {
            const dogSireDamDamRecord = dogSireDamRecord.dam;
            this.dogSireDamDamNameFull = this.createDogName(dogSireDamDamRecord.name, dogSireDamDamRecord.health, dogSireDamDamRecord.callName);
            this.dogSireDamDamName = dogSireDamDamRecord.name.titleString;
            this.dogSireDamDamHealth = dogSireDamDamRecord.healthPub.split(' ');
            this.sireDamDamId = dogSireDamDamRecord.dog_id;
            if (dogSireDamDamRecord.hasOwnProperty('sire')) {
              const dogSireDamDamSireRecord = dogSireDamDamRecord.sire;
              this.dogSireDamDamSireName = this.createDogName(dogSireDamDamSireRecord.name, dogSireDamDamSireRecord.health, dogSireDamDamSireRecord.callName);
              this.sireDamDamSireId = dogSireDamDamSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogSireDamDamRecord.hasOwnProperty('dam')) {
              const dogSireDamDamDamRecord = dogSireDamDamRecord.dam;
              this.dogSireDamDamDamName = this.createDogName(dogSireDamDamDamRecord.name, dogSireDamDamDamRecord.health, dogSireDamDamDamRecord.callName);
              this.sireDamDamDamId = dogSireDamDamDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
        } else {
          this.missingPedigreeDataExists = true;
        }
      } else {
        this.missingPedigreeDataExists = true;
      }
      if (dogRecord.hasOwnProperty('dam')) {
        const dogDamRecord = dogRecord.dam;
        this.dogDamNameFull = this.createDogName(dogDamRecord.name, dogDamRecord.health, dogDamRecord.callName);
        this.dogDamName = dogDamRecord.name.titleString;
        this.dogDamHealth = dogDamRecord.healthPub.split(' ');
        this.damId = dogDamRecord.dog_id;
        if (dogDamRecord.hasOwnProperty('sire')) {
          const dogDamSireRecord = dogDamRecord.sire;
          this.dogDamSireNameFull = this.createDogName(dogDamSireRecord.name, dogDamSireRecord.health, dogDamSireRecord.callName);
          this.dogDamSireName = dogDamSireRecord.name.titleString;
          this.dogDamSireHealth = dogDamSireRecord.healthPub.split(' ');
          this.damSireId = dogDamSireRecord.dog_id;
          if (dogDamSireRecord.hasOwnProperty('sire')) {
            const dogDamSireSireRecord = dogDamSireRecord.sire;
            this.dogDamSireSireNameFull = this.createDogName(dogDamSireSireRecord.name, dogDamSireSireRecord.health, dogDamSireSireRecord.callName);
            this.dogDamSireSireName = dogDamSireSireRecord.name.titleString;
            this.dogDamSireSireHealth = dogDamSireSireRecord.healthPub.split(' ');
            this.damSireSireId = dogDamSireSireRecord.dog_id;
            if (dogDamSireSireRecord.hasOwnProperty('sire')) {
              const dogDamSireSireSireRecord = dogDamSireSireRecord.sire;
              this.dogDamSireSireSireName = this.createDogName(dogDamSireSireSireRecord.name, dogDamSireSireSireRecord.health, dogDamSireSireSireRecord.callName);
              this.damSireSireSireId = dogDamSireSireSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogDamSireSireRecord.hasOwnProperty('dam')) {
              const dogDamSireSireDamRecord = dogDamSireSireRecord.dam;
              this.dogDamSireSireDamName = this.createDogName(dogDamSireSireDamRecord.name, dogDamSireSireDamRecord.health, dogDamSireSireDamRecord.callName);
              this.damSireSireDamId = dogDamSireSireDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
          if (dogDamSireRecord.hasOwnProperty('dam')) {
            const dogDamSireDamRecord = dogDamSireRecord.dam;
            this.dogDamSireDamNameFull = this.createDogName(dogDamSireDamRecord.name, dogDamSireDamRecord.health, dogDamSireDamRecord.callName);
            this.dogDamSireDamName = dogDamSireDamRecord.name.titleString;
            this.dogDamSireDamHealth = dogDamSireDamRecord.healthPub.split(' ');
            this.damSireDamId = dogDamSireDamRecord.dog_id;
            if (dogDamSireDamRecord.hasOwnProperty('sire')) {
              const dogDamSireDamSireRecord = dogDamSireDamRecord.sire;
              this.dogDamSireDamSireName = this.createDogName(dogDamSireDamSireRecord.name, dogDamSireDamSireRecord.health, dogDamSireDamSireRecord.callName);
              this.damSireDamSireId = dogDamSireDamSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogDamSireDamRecord.hasOwnProperty('dam')) {
              const dogDamSireDamDamRecord = dogDamSireDamRecord.dam;
              this.dogDamSireDamDamName = this.createDogName(dogDamSireDamDamRecord.name, dogDamSireDamDamRecord.health, dogDamSireDamDamRecord.callName);
              this.damSireDamDamId = dogDamSireDamDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
        } else {
          this.missingPedigreeDataExists = true;
        }
        if (dogDamRecord.hasOwnProperty('dam')) {
          const dogDamDamRecord = dogDamRecord.dam;
          this.dogDamDamNameFull = this.createDogName(dogDamDamRecord.name, dogDamDamRecord.health, dogDamDamRecord.callName);
          this.dogDamDamName = dogDamDamRecord.name.titleString;
          this.dogDamDamHealth = dogDamDamRecord.healthPub.split(' ');
          this.damDamId = dogDamDamRecord.dog_id;
          if (dogDamDamRecord.hasOwnProperty('sire')) {
            const dogDamDamSireRecord = dogDamDamRecord.sire;
            this.dogDamDamSireNameFull = this.createDogName(dogDamDamSireRecord.name, dogDamDamSireRecord.health, dogDamDamSireRecord.callName);
            this.dogDamDamSireName = dogDamDamSireRecord.name.titleString;
            this.dogDamDamSireHealth = dogDamDamSireRecord.healthPub.split(' ');
            this.damDamSireId = dogDamDamSireRecord.dog_id;
            if (dogDamDamSireRecord.hasOwnProperty('sire')) {
              const dogDamDamSireSireRecord = dogDamDamSireRecord.sire;
              this.dogDamDamSireSireName = this.createDogName(dogDamDamSireSireRecord.name, dogDamDamSireSireRecord.health, dogDamDamSireSireRecord.callName);
              this.damDamSireSireId = dogDamDamSireSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogDamDamSireRecord.hasOwnProperty('dam')) {
              const dogDamDamSireDamRecord = dogDamDamSireRecord.dam;
              this.dogDamDamSireDamName = this.createDogName(dogDamDamSireDamRecord.name, dogDamDamSireDamRecord.health, dogDamDamSireDamRecord.callName);
              this.damDamSireDamId = dogDamDamSireDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
          if (dogDamDamRecord.hasOwnProperty('dam')) {
            const dogDamDamDamRecord = dogDamDamRecord.dam;
            this.dogDamDamDamNameFull = this.createDogName(dogDamDamDamRecord.name, dogDamDamDamRecord.health, dogDamDamDamRecord.callName);
            this.dogDamDamDamName = dogDamDamDamRecord.name.titleString;
            this.dogDamDamDamHealth = dogDamDamDamRecord.healthPub.split(' ');
            this.damDamDamId = dogDamDamDamRecord.dog_id;
            if (dogDamDamDamRecord.hasOwnProperty('sire')) {
              const dogDamDamDamSireRecord = dogDamDamDamRecord.sire;
              this.dogDamDamDamSireName = this.createDogName(dogDamDamDamSireRecord.name, dogDamDamDamSireRecord.health, dogDamDamDamSireRecord.callName);
              this.damDamDamSireId = dogDamDamDamSireRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
            if (dogDamDamDamRecord.hasOwnProperty('dam')) {
              const dogDamDamDamDamRecord = dogDamDamDamRecord.dam;
              this.dogDamDamDamDamName = this.createDogName(dogDamDamDamDamRecord.name, dogDamDamDamDamRecord.health, dogDamDamDamDamRecord.callName);
              this.damDamDamDamId = dogDamDamDamDamRecord.dog_id;
            } else {
              this.missingPedigreeDataExists = true;
            }
          } else {
            this.missingPedigreeDataExists = true;
          }
        } else {
          this.missingPedigreeDataExists = true;
        }
      } else {
        this.missingPedigreeDataExists = true;
      }
    } else {
      this.missingPedigreeDataExists = true;
    }
  }

  hasPictures = false;
  dogPictures: DogImage[] = [];
  dogCoatColor = 'Unknown';
  dogDOB = 'Unknown';
  dogDOBPub = 'Unknown';
  dogGender = 'Unknown';
  @Output() tabSelected = new EventEmitter();
  @Output() itemApprovedOrDenied = new EventEmitter();

  constructor(private router: Router,
              private modalService: ModalService,
              private clipboard: Clipboard,
              private breadcrumbs: BreadcrumbsService) {
    this._dog = {registry_info: []};
    LeaderLine.positionByWindowResize = false;
  }

  ngOnInit() {
    const tabEl = document.querySelector('a[data-bs-target="#v-pills-pedigree"]')
    tabEl?.addEventListener('shown.bs.tab', event => {
      this.tabSelected.emit('Pedigree');
      this.line1 = new LeaderLine(this.sire?.nativeElement, this.dog?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      //let pedview = document.getElementById('pedigree-view-2');
      this.line2 = new LeaderLine(this.dam?.nativeElement, this.dog?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line3 = new LeaderLine(this.sireSire?.nativeElement, this.sire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line4 = new LeaderLine(this.sireDam?.nativeElement, this.sire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line5 = new LeaderLine(this.damSire?.nativeElement, this.dam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line6 = new LeaderLine(this.damDam?.nativeElement, this.dam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line7 = new LeaderLine(this.sireSireSire?.nativeElement, this.sireSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line8 = new LeaderLine(this.sireSireDam?.nativeElement, this.sireSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line9 = new LeaderLine(this.sireDamSire?.nativeElement, this.sireDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line10 = new LeaderLine(this.sireDamDam?.nativeElement, this.sireDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line11 = new LeaderLine(this.damSireSire?.nativeElement, this.damSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line12 = new LeaderLine(this.damSireDam?.nativeElement, this.damSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line13 = new LeaderLine(this.damDamSire?.nativeElement, this.damDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line14 = new LeaderLine(this.damDamDam?.nativeElement, this.damDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line15 = new LeaderLine(this.sireSireSireSire?.nativeElement, this.sireSireSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line16 = new LeaderLine(this.sireSireSireDam?.nativeElement, this.sireSireSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line17 = new LeaderLine(this.sireSireDamSire?.nativeElement, this.sireSireDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line18 = new LeaderLine(this.sireSireDamDam?.nativeElement, this.sireSireDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line19 = new LeaderLine(this.sireDamSireSire?.nativeElement, this.sireDamSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line20 = new LeaderLine(this.sireDamSireDam?.nativeElement, this.sireDamSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line21 = new LeaderLine(this.sireDamDamSire?.nativeElement, this.sireDamDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line22 = new LeaderLine(this.sireDamDamDam?.nativeElement, this.sireDamDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line23 = new LeaderLine(this.damSireSireSire?.nativeElement, this.damSireSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line24 = new LeaderLine(this.damSireSireDam?.nativeElement, this.damSireSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line25 = new LeaderLine(this.damSireDamSire?.nativeElement, this.damSireDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line26 = new LeaderLine(this.damSireDamDam?.nativeElement, this.damSireDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line27 = new LeaderLine(this.damDamSireSire?.nativeElement, this.damDamSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line28 = new LeaderLine(this.damDamSireDam?.nativeElement, this.damDamSire?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line29 = new LeaderLine(this.damDamDamSire?.nativeElement, this.damDamDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });
      this.line30 = new LeaderLine(this.damDamDamDam?.nativeElement, this.damDamDam?.nativeElement, {
        color: 'rgba(0, 0, 0, 1)',
        size: 1,
        endPlugSize: 4
      });

    });
  }

  ngAfterViewInit() {
    const tabElh = document.querySelector('a[data-bs-target="#v-pills-pedigree"]')
    tabElh?.addEventListener('hidden.bs.tab', event => {
      try {
        this.line1?.remove();
        this.line2?.remove();
        this.line3?.remove();
        this.line4?.remove();
        this.line5?.remove();
        this.line6?.remove();
        this.line7?.remove();
        this.line8?.remove();
        this.line9?.remove();
        this.line10?.remove();
        this.line11?.remove();
        this.line12?.remove();
        this.line13?.remove();
        this.line14?.remove();
        this.line15?.remove();
        this.line16?.remove();
        this.line17?.remove();
        this.line18?.remove();
        this.line19?.remove();
        this.line20?.remove();
        this.line21?.remove();
        this.line22?.remove();
        this.line23?.remove();
        this.line24?.remove();
        this.line25?.remove();
        this.line26?.remove();
        this.line27?.remove();
        this.line28?.remove();
        this.line29?.remove();
        this.line30?.remove();
      } catch (e) {
        // no issue here
      }

    });

    const spv = document.getElementById('pedigree-view-2')

    spv?.addEventListener('scroll', event => {
      this.line1?.position();
      this.line2?.position();
      this.line3?.position();
      this.line4?.position();
      this.line5?.position();
      this.line6?.position();
      this.line7?.position();
      this.line8?.position();
      this.line9?.position();
      this.line10?.position();
      this.line11?.position();
      this.line12?.position();
      this.line13?.position();
      this.line14?.position();
      this.line15?.position();
      this.line16?.position();
      this.line17?.position();
      this.line18?.position();
      this.line19?.position();
      this.line20?.position();
      this.line21?.position();
      this.line22?.position();
      this.line23?.position();
      this.line24?.position();
      this.line25?.position();
      this.line26?.position();
      this.line27?.position();
      this.line28?.position();
      this.line29?.position();
      this.line30?.position();
    });
    window.addEventListener('resize', event => {
      this.line1?.position();
      this.line2?.position();
      this.line3?.position();
      this.line4?.position();
      this.line5?.position();
      this.line6?.position();
      this.line7?.position();
      this.line8?.position();
      this.line9?.position();
      this.line10?.position();
      this.line11?.position();
      this.line12?.position();
      this.line13?.position();
      this.line14?.position();
      this.line15?.position();
      this.line16?.position();
      this.line17?.position();
      this.line18?.position();
      this.line19?.position();
      this.line20?.position();
      this.line21?.position();
      this.line22?.position();
      this.line23?.position();
      this.line24?.position();
      this.line25?.position();
      this.line26?.position();
      this.line27?.position();
      this.line28?.position();
      this.line29?.position();
      this.line30?.position();
    });
  }

  ngOnDestroy() {
    try {
      this.line1?.remove();
      this.line2?.remove();
      this.line3?.remove();
      this.line4?.remove();
      this.line5?.remove();
      this.line6?.remove();
      this.line7?.remove();
      this.line8?.remove();
      this.line9?.remove();
      this.line10?.remove();
      this.line11?.remove();
      this.line12?.remove();
      this.line13?.remove();
      this.line14?.remove();
      this.line15?.remove();
      this.line16?.remove();
      this.line17?.remove();
      this.line18?.remove();
      this.line19?.remove();
      this.line20?.remove();
      this.line21?.remove();
      this.line22?.remove();
      this.line23?.remove();
      this.line24?.remove();
      this.line25?.remove();
      this.line26?.remove();
      this.line27?.remove();
      this.line28?.remove();
      this.line29?.remove();
      this.line30?.remove();
    } catch (e) {
      // no issue here
    }
  }

  removeLines() {
    try {
      this.line1?.remove();
      this.line2?.remove();
      this.line3?.remove();
      this.line4?.remove();
      this.line5?.remove();
      this.line6?.remove();
      this.line7?.remove();
      this.line8?.remove();
      this.line9?.remove();
      this.line10?.remove();
      this.line11?.remove();
      this.line12?.remove();
      this.line13?.remove();
      this.line14?.remove();
      this.line15?.remove();
      this.line16?.remove();
      this.line17?.remove();
      this.line18?.remove();
      this.line19?.remove();
      this.line20?.remove();
      this.line21?.remove();
      this.line22?.remove();
      this.line23?.remove();
      this.line24?.remove();
      this.line25?.remove();
      this.line26?.remove();
      this.line27?.remove();
      this.line28?.remove();
      this.line29?.remove();
      this.line30?.remove();
    } catch (e) {
      // no issue here
    }
  }

  private createDogName(name: PedigreeNameRecord, health: string, callName: string): string {
    let dogName = name?.titleString;
    if ((callName != null) && (callName != '')) {
      dogName += ' (' + callName + ')';
    }
    if ((health != null) && (health != '')) {
      dogName += '<br>Health: ' + health;
    }
    if (dogName == '') {
      dogName = 'Unknown';
    }
    return dogName;
  }

  gotoDog(dogId: number | undefined, dogName: string) {
    if (dogId && (dogId > 0)) {
      let len = dogName.indexOf('<br>');
      if (len >= 0) {
        this.breadcrumbs.pushDog(dogId, dogName.substring(0, len));
      } else {
        this.breadcrumbs.pushDog(dogId, dogName);
      }
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.navigateByUrl('/search');
    }
  }

  getDogGenderClass() {
    if (this.dogGender === 'Male') {
      return 'peddog dog';
    } else {
      return 'peddog bitch';
    }
  }

  showPubReport() {
    this.modalService.open('modal-pub');
  }

  closePubReport() {
    this.modalService.close();
  }

  exportPedigree() {
    const pev = document.getElementById('pedigreeExport');
    if (pev != null) {
      pev.classList.remove('d-none');
      const width = this.pedigreeExportView?.nativeElement.clientWidth;
      const height = this.pedigreeExportView?.nativeElement.clientHeight + 40;
      let orientation: "p" | "portrait" | "l" | "landscape";
      let imageUnit: "pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc" = 'pt';
      if (width > height) {
        orientation = 'l';
      } else {
        orientation = 'p';
      }
      domToImage
        .toPng(this.pedigreeExportView?.nativeElement, {
          width: width,
          height: height
        })
        .then((result: string | HTMLImageElement | HTMLCanvasElement | Uint8Array | RGBAData) => {
          let jsPdfOptions = {
            orientation: orientation,
            unit: imageUnit,
            format: [width + 50, height + 220]
          };
          const pdf = new jsPDF(orientation, imageUnit, [width + 50, height + 220], false);
          pdf.addImage(result, 'PNG', 25, 25, width, height);
          pdf.save('NCAPedigreeReport-' + DateTime.now().toFormat('MM-dd-yyyy')+ '.pdf');
          pev.classList.add('d-none');
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }
  copy() {
    const ps =  document.getElementById('pubSection');
    if (ps) {
      const success =this.clipboard.copy(ps.innerText);
      if (success) {
        this.tooltipcontent?.show();
        setTimeout(() => {
          this.tooltipcontent?.hide();
        }, 2000);
      } else {
        this.failedtooltipcontent?.show();
        setTimeout(() => {
          this.tooltipcontent?.hide();
        }, 2000);
      }
    } else {
      this.failedtooltipcontent?.show();
      setTimeout(() => {
        this.tooltipcontent?.hide();
      }, 2000);
    }

  }
}

<div class="content text-center">
  <div>
    <div class="container-fluid table-container">
      <div *ngIf="!(editMode === EditModes.editable || editMode === EditModes.waitingForApproval)" class="text-center mb-4">
        <button class="btn btn-secondary btn-spacing" (click)="exportSummary()">Export Printable Summary</button>
      </div>
      <div #summaryExportView id="summary-section">
        <div class="grid_12 text-center">
          <header>
            <h4>Registered Name: {{ dog.registered_name }}</h4>
          </header>
        </div>
        <h3>Profile</h3>
        <app-profile [dog]="dog" [pedigree]="pedigree" [summary]="true"
                     (itemApprovedOrDenied)="childItemApprovedOrDenied()"
                     [editMode]="editMode"
                     [providedDOD]="providedDOD" [providedDOB]="providedDOB"></app-profile>
        <div id="pedPart">
          <h3>Pedigree</h3>
          <app-pedigree [dog]="dog" [pedigree]="pedigree.pedigree"
                        (itemApprovedOrDenied)="childItemApprovedOrDenied()"
                        [summary]="true"></app-pedigree>
        </div>
        <h3>Awards</h3>
        <app-awards [dog]="dog" [awards]="awards" [summary]="true"
                    (itemApprovedOrDenied)="childItemApprovedOrDenied()"
                    [editMode]="editMode"></app-awards>
        <h3>Health Info</h3>
        <app-health [dog]="dog" [healths]="healths" [summary]="true" [editMode]="editMode"
                    [hasCauseOfDeath]="hasCauseOfDeath" [formId]="formId"
                    (itemApprovedOrDenied)="childItemApprovedOrDenied()"
                    [hasHealthCondition]="hasHealthCondition"></app-health>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Award, Dog, DogImage } from '../models/dog.model';
import { DateTime } from 'luxon';
import { TokenStorageService } from '../services/token-storage.service';
import { EditModes } from '../models/forms.model';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent implements OnInit {
  @Input() summary: boolean = false;
  @Input() editMode= EditModes.notEditable;
  _dog: Dog | undefined;
  @Input('dog')
  set in(val: Dog) {
    this._dog = val;
    this.dogCoatColor = this._dog?.coat_color?.coat_color_description ?? 'Unknown';
    const datenumeric = this._dog?.date_of_birth ?? '1970-01-01T00:00:00.000Z';
    const dateDate = DateTime.fromISO(datenumeric);
    this.dogDOB = dateDate.toUTC().toISODate() ?? 'Unknown';
    this.dogGender = this._dog?.sex == 'M' ? 'Male' : 'Female' ?? 'Unknown';
  }
  _awards: Award[] = [];
  @Input('awards')
  set in2(val: Award[]) {
    this._awards = val;
    this._awards.forEach(award => {
      const datenumeric = award.award_date ?? '1970-01-01T00:00:00.000Z';
      const dateDate = DateTime.fromISO(datenumeric);
      award.award_date = dateDate.toUTC().toISODate() ?? 'Unknown';

    })
  }
  hasPictures = false;
  dogPictures: DogImage[] = [];
  dogCoatColor = 'Unknown';
  dogDOB = 'Unknown';
  dogGender = 'Unknown';
  @Output() tabSelected = new EventEmitter();
  @Output() itemApprovedOrDenied = new EventEmitter();

  constructor(private  tokenService: TokenStorageService) {
  }
  getRomPoints() {
    if (this.tokenService.isUserLoggedIn()) {
      if (this._dog?.rom_points) {
        return this._dog.rom_points;
      } else {
        return "None";
      }
    } else {
      return "Members Only";
    }
  }
  ngOnInit(): void {
    const tabEl = document.querySelector('a[data-bs-target="#v-pills-awards"]')
    tabEl?.addEventListener('shown.bs.tab', event => {
      this.tabSelected.emit('Awards');
    });
  }
  getAwardsClass() {
    if (this.summary) {
      return "table table-sm summary-table table-bordered table-striped table-hover mt-4";
    } else {
      return "table table-bordered table-striped table-hover mt-4";
    }
  }
  addRecord() {

  }
  editAward(theAward: Award) {
  }

  protected readonly EditModes = EditModes;
}

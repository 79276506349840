<div class="content text-center">
  <div>
    <div class="container-fluid table-container">
      <div class="grid_12">
        <div *ngIf="!summary">
          <header>
            <h4>Registered Name: {{ _dog?.registered_name }}</h4>
          </header>
        </div>
        <table class="table top-section">
          <tr>
            <td>CHIC:</td><td>{{ _dog?.chic_num }}</td>
          </tr>
          <tr>
            <td>TOD:</td><td>{{ _dog?.TOD }}</td>
          </tr>
          <tr>
            <td>TODD:</td><td>{{ _dog?.TODD }}</td>
          </tr>
          <tr>
            <td>Date of Birth:</td><td>{{ dogDOB }}</td>
          </tr>
          <tr>
            <td>Date of Death:</td><td>{{ dogDOD }}</td>
          </tr>
          <tr>
            <td>Coat Color:</td><td>{{ dogCoatColor }}</td>
          </tr>
          <tr>
            <td>Gender:</td><td>{{ dogGender }}</td>
          </tr>
          <tr>
            <td>Breeder:</td><td>{{ _dog?.breeder?.breeder_name }}</td>
          </tr>
          <tr>
            <td>Owner:</td><td>{{ _dog?.owner?.owner_name }}</td>
          </tr>
        </table>
      </div>
      <div *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="float-end">
        <button class="btn btn-secondary" (click)="addRecord()">Add New Health Test</button>
      </div>
      <div *ngIf="!summary && app_num !== -1">
        <div class="row mt-2">
          <div class="col-md-12 text-center">

          </div>
        </div>
      </div>
      <div class="col-md-12 text-start pt-2" style="border-top: 1px solid black;">
        <div class="float-start">
          <h5>OFA Test Records</h5>
          <p><span class="w-100">These are health tests recorded by the OFA for this dog</span></p>
        </div>
        <div *ngIf="!summary" class="float-end">
          <p><span style="width: fit-content;"><button class="btn btn-secondary btn-spacing" style="width: max-content;" (click)="gotoOfa()">View on OFA Website</button></span></p>
        </div>
      </div>
      <div class="grid_12 table-responsive overflow-y-hidden" style="display: block;clear: both;content: '';">
         <table [ngClass]="getHealthClass()">
          <thead>
          <tr>
            <th>Test Name</th>
            <th>Test Date</th>
            <th>Report Date</th>
            <th>Age(mos)</th>
            <th>Organization</th>
            <th>Test Number</th>
            <th>Results</th>
            <th *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval">Edit Health</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let health of _healths.health">
            <td>{{ health.test_name }}</td>
            <td>{{ health.test_date }}</td>
            <td>{{ health.report_date }}</td>
            <td>{{ health.age }}</td>
            <td>{{ health.test_organization }}</td>
            <td>{{ health.test_number }}</td>
            <td>{{ health.test_results }}</td>
            <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"><a class="p-2" href="" (click)="editHealth(health)">Edit</a></td>
          </tr>
          <tr *ngIf="numberOfHealths === 0">
            <td colspan="7">No health data found</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-12 text-start pt-2" style="border-top: 1px solid black;">
          <div class="row">
            <div class="col-md-7">
              <h5>Publicly Viewable Health Conditions</h5>
              <p>These are health conditions that the owner/breeder has reported for this dog</p>
            </div>
            <div class="col-md-5">
              <div class="table-responsive overflow-y-hidden w-100">
                <table [ngClass]="getHealthClass()">
                  <thead>
                  <tr>
                    <th>Condition</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let hc of _healths.conditions">
                    <td>{{ hc.title }} <span class="fw-bold">{{ getIsCauseOfDeath(hc) }}</span></td>
                  </tr>
                  <tr *ngIf="numberOfPublicHealths === 0">
                    <td>No public conditions recorded</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div *ngIf="summary">
            <div  [ngClass]="getEditModeClass()">
              <h4>Health Conditions and Cause of Death</h4>
              <table class="table top-section text-nowrap" >
                <tr>
                  <td>Number of Health Conditions:</td><td>{{ _healths.conditionsCount }}</td>
                </tr>
                <tr>
                  <td>Cause of Death: </td><td>{{ getCauseOfDeathValue() }}</td>
                </tr>
              </table>
            </div>
            <form [formGroup]="healthEditForm" id="profile-edit-form" class="input-group text-start" role="form">
              <div *ngIf="hasHealthCondition || hasCauseOfDeath">
                <div *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100">
                  <p class="fs-6 fw-bold">Requested Actions:</p>
                </div>
                <div *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100">
                  <p class="ms-2">Please verify that the person who submitted this request is the owner or breeder of the dog)</p>
                </div>
              </div>
              <table class="table table-bordered ms-3">
                <tr *ngIf="hasHealthCondition || hasCauseOfDeath">
                  <td class="d-inline-flex align-items-baseline">{{ healthConditionString }}</td>
                  <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval">
                    <a *ngIf="showSubmit" class="p-2" (click)="submitHealthConditionField()">Confirm Owner</a>
                    <p class="mb-0 pt-0 pb-0" *ngIf="!showSubmit && !showDone">Waiting for Approval</p>
                    <p class="mb-0 pt-0 pb-0" *ngIf="showDone">Change Done and Approved</p>
                  </td>
                  <td class="ms-3 mx-auto border-4 border-primary" *ngIf="isSuperAdmin && showApprove">
                    <a style="padding: 3px;cursor: pointer;" (click)="approveOrDenyField(true)">Approve?</a>
                    <a style="padding: 3px;cursor: pointer;" (click)="approveOrDenyField(false)">Reject?</a>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>
      <div  *ngIf="!summary" class="mt-4  border-top">
        <app-data-display></app-data-display>
      </div>
    </div>
  </div>
</div>

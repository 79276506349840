import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DogService } from '../../services/dog.service';
import { DateTime } from 'luxon';
import { Form } from '@angular/forms';
import { DogForm, EditModes } from '../../models/forms.model';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { ACommitteeMember, Award, Dog, DogPedigree, Health, HealthResponse } from '../../models/dog.model';
import { TokenStorageService } from '../../services/token-storage.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.scss']
})
export class FormDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('responseMessage') responseMessage: any;
  thisForm: DogForm;
  formId: number;
  submitReadableDate = '';
  mySpinner: boolean;
  private sub: any;
  formData: any;
  curUser: any;
  theDogId = 0;
  theDogNotes = '';
  committeeId: number | undefined;
  imageArray: any[] = [];
  selectedDogInfo: Dog;
  selectedDogPedigree: DogPedigree;
  selectedDogAwards: Award[] = [];
  selectedDogHealth: HealthResponse = {health: [{}], conditions: [{}], conditionsCount: 0};
  showDogInfo = false;
  dbAdmins: ACommitteeMember[] = [];
  private unsubscribe$ = new Subject<void>();
  editMode = EditModes.notEditable;
  isAssignable = false;
  wantsToChangeCallName = false;
  wantsToAddDeathInfo = false;
  wantsToChangeOtherInfo = false;
  wantsToUpdateHealthInfo = false;
  wantsToChangeTitles = false;
  wantsToChangeSireDam = false;
  wantsToAddHealthCondition = false;
  wantsToAddDOB = false;
  wantsToAddDOD = false;
  dateOfDeath = '';
  dateOfBirth = '';
  addedHealthCategory = '';
  addedHealthCategoryOption = '';
  healthConditionPublic = 0;
  causeOfDeathCategory = '';
  causeOfDeathCategoryOption = '';
  causeOfDeathPublic = 0;
  actionCount = 1;
  formComplete = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private service: DogService,
              private tokenService: TokenStorageService,
              private domSanitizer: DomSanitizer,
              private  ngZone: NgZone) {
    this.formId = 0;
    this.mySpinner = false;
    this.thisForm = {committeeId: 0, submitDate: '', formData: '', id: 1, responseMessage: ''};
    this.selectedDogInfo = { registry_info: [] };
    this.selectedDogPedigree = {acl3: 0, acl4: 0, pedigree: {}};
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.service.getDbAdmins()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('committeeMembers')) {
            this.dbAdmins = res.committeeMembers;
          }
        });
      });
    this.service.loadDbAdmins();

    this.sub = this.route.paramMap.subscribe(params => {
      this.ngZone.run(() => {
        this.formId = +params.get('id')!; // (+) converts string 'id' to a number
        this.mySpinner = true;
        this.getFormData();
      });
    });
  }

  getFormData() {
    this.service.getForm(this.formId)
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.thisForm = res;
          this.curUser = this.tokenService.getUser();

          this.isAssignable = this.tokenService.userHasRole('superAdmin') ||
            ((this.thisForm.assignedId === this.curUser.waId) && (this.thisForm.currentState === 'assigned'));
          if ((this.thisForm.currentState === 'resolvedDone') || (this.thisForm.currentState === 'resolvedRejected')) {
            this.editMode = EditModes.done;
          } else if ((this.thisForm.assignedId === this.curUser.waId) &&
            (this.thisForm.currentState === 'assigned')) {
            this.editMode = EditModes.editable;
          } else if (this.thisForm.currentState === 'pendingApproval') {
            this.editMode = EditModes.waitingForApproval;
          }
          this.committeeId = this.thisForm.committeeId
          const dateNumeric = this.thisForm.submitDate;
          this.submitReadableDate = DateTime.fromISO(dateNumeric).toLocaleString(DateTime.DATETIME_FULL);
          this.formData = this.thisForm.formData; // JSON.parse(this.thisForm.formData);
          this.theDogId = this.formData['dogId'];
          this.theDogNotes = this.formData['dogNotes'];
          if (this.formData.hasOwnProperty('death')) {
            this.actionCount = 1;
            this.wantsToChangeCallName = this.formData['callName'];
            if (this.wantsToChangeCallName) this.actionCount++;
            this.wantsToAddDeathInfo = this.formData['death'];
            if (this.wantsToAddDeathInfo) this.actionCount++;
            this.wantsToChangeOtherInfo = this.formData['other'];
            if (this.wantsToChangeOtherInfo) this.actionCount++;
            this.wantsToUpdateHealthInfo = this.formData['health'];
            if (this.wantsToUpdateHealthInfo) this.actionCount++;
            this.wantsToChangeTitles = this.formData['titles'];
            if (this.wantsToChangeTitles) this.actionCount++;
            this.wantsToChangeSireDam = this.formData['sireDam'];
            if (this.wantsToChangeSireDam) this.actionCount++;
            this.wantsToAddHealthCondition = this.formData['addHealth'];
            if (this.wantsToAddHealthCondition) this.actionCount++;
            const gotDob: NgbDateStruct | undefined = this.formData['dateOfBirth'];
            if (gotDob) {
              this.wantsToAddDOB = true;
              this.actionCount++;
              this.dateOfBirth = gotDob.year.toString() + '-' + gotDob.month.toString().padStart(2, '0') + '-' + gotDob.day.toString().padStart(2, '0');
            }
            const gotDod: NgbDateStruct | undefined = this.formData['dateOfDeath'];
            if (gotDod) {
              this.wantsToAddDOD = true;
              this.actionCount++;
              this.dateOfDeath = gotDod.year.toString() + '-' + gotDod.month.toString().padStart(2, '0') + '-' + gotDod.day.toString().padStart(2, '0');
            }
            this.addedHealthCategory = this.formData['healthCategory'];
            this.addedHealthCategoryOption = this.formData['healthCategoryOption'];
            this.causeOfDeathCategory = this.formData['causeOfDeathCategory'];
            this.causeOfDeathCategoryOption = this.formData['causeOfDeathCategoryOption'];
            if (this.formData.hasOwnProperty('healthConditionPublic')) {
              this.healthConditionPublic = this.formData['healthConditionPublic'];
              this.causeOfDeathPublic = this.formData['causeOfDeathPublic'];
            }
          }
          if (this.formData.hasOwnProperty('files')) {
            const imAr: string[] = this.formData['files'];
            this.imageArray = imAr.map((image) => {
              var isViewable = true;
              if ((image.toLowerCase().endsWith('.pdf')) || (image.toLowerCase().endsWith('.tiff')) || (image.toLowerCase().endsWith('.tif'))) {
                isViewable = false;
              }
              return {
                imagePath: "https://images.ncanewfs.org/blob?filePath=" + encodeURI(image),
                isViewable: isViewable
              }
            });
            /*  this.imageArray.forEach((image) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob'; //so you can access the response like a normal URL
                xhr.onreadystatechange = function () {
                  if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
                    var img = document.createElement('img');
                    img.src = URL.createObjectURL(xhr.response); //create <img> with src set to the blob
                    const aDiv = document.getElementById('imagesArray');
                    aDiv?.appendChild(img);
                  }
                };
                xhr.open('GET', 'https://images.ncanewfs.org/blob?filePath=' + image, true);
                xhr.setRequestHeader('X-My-Custom-Header', 'blobImage');
                xhr.send();
              });

             */
          }
          forkJoin({
            dogData: this.service.getDogInfo(this.theDogId),
            dogPed: this.service.getDogPedigree(this.theDogId, 3),
            dogAwards: this.service.getDogAwards(this.theDogId),
            dogHealth: this.service.getDogHealth(this.theDogId, true),
          })
            .subscribe(data => {
              this.mySpinner = false;
              this.selectedDogInfo = data.dogData;
              this.selectedDogPedigree = data.dogPed;
              if (data.dogHealth.hasOwnProperty('health')) {
                this.selectedDogHealth = data.dogHealth;
              } else {
                this.selectedDogHealth = {health: [{}], conditions: [{}], conditionsCount: 0};
              }
              if (data.dogAwards.hasOwnProperty('allAwards')) {
                this.selectedDogAwards = data.dogAwards.allAwards;
              } else {
                this.selectedDogAwards = [];
              }
              this.showDogInfo = true;
            });
        });
      });

  }
  reassign(member: ACommitteeMember) {
    console.log('reassigning to: ' + member.firstName);
    this.thisForm.toState = this.thisForm.currentState;
    this.thisForm.toState = 'assigned';
    this.thisForm.assignedTo = member.firstName + ' ' + member.lastName;
    this.thisForm.assignedId = member.memberId;
    this.service.postForm(this.formId, this.thisForm)
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.getFormData();
        });
      });
  }

  changeState(toState: string) {
    console.log('changing state to ' + toState);
    this.thisForm.toState = toState;
    this.service.postForm(this.formId, this.thisForm)
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.getFormData();
        });
      });
  }

  isSuperAdmin() {
    return this.tokenService.userHasRole('superAdmin');
  }
  submitForApproval() {
    this.thisForm.toState = 'pendingApproval';
    this.thisForm.assignedTo = 'Marylou Zimmerman';
    this.thisForm.assignedId = 34404855;
    this.service.postForm(this.formId, this.thisForm)
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.getFormData();
        });
      });
  }

  allPendingDone() {
    return false;
  }
  completeForm() {
    // TODO: get the resolved state based on what was approved/rejected
    const toState = 'resolvedDone';
    let respMessage = this.responseMessage.nativeElement.value;
    if (respMessage.length > 1024) {
      respMessage = respMessage.substring(0, 1023);
    }
    console.log('changing state to ' + toState);
    this.thisForm.toState = toState;
    this.thisForm.responseMessage = respMessage;
    this.service.postForm(this.formId, this.thisForm)
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.getFormData();
        });
      });

  }

  itemApprovedOrDenied(event: any) {

  }
  getStateDisable(toState: string) {
    let toDisable = true;
    switch (toState) {
      case 'submitForApproval':
        if (this.thisForm.currentState === 'assigned') {
          toDisable = false;
        }
        break;
      case 'approve':
      case 'reject':
        if ((this.tokenService.userHasRole('superAdmin')) &&
          (this.thisForm.currentState === 'pendingApproval')) {
          toDisable = false;
        }
        break;
    }
    return toDisable;
  }
  getAssignClass(state: string) {
    let classString = 'dropdown-item';
    switch (state) {
      case 'assigned':
        if ((this.thisForm.currentState !== 'submitted') &&
          (this.thisForm.currentState !== 'assigned')) {
          classString += ' disabled';
        }
        break;
      case 'pendingApproval':
        if (this.thisForm.currentState !== 'assigned') {
          classString += ' disabled';
        }
        break;
      case 'resolvedDone':
        if ((this.thisForm.currentState !== 'pendingApproval') ||
          (!this.tokenService.userHasRole('superAdmin')))
        {
          classString += ' disabled';
        }
        break;
      case 'resolvedRejected':
        if ((this.thisForm.currentState !== 'pendingApproval') ||
          (!this.tokenService.userHasRole('superAdmin')))
        {
          classString += ' disabled';
        }
        break;
    }
    return classString;
  }
}

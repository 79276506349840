import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import {
  Award,
  Dog,
  DogImage,
  Health,
  HealthCategoriesResponse, HealthCondition,
  HealthResponse,
  PendingChanges, PendingHealthChanges
} from '../models/dog.model';
import { DateTime } from 'luxon';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../services/token-storage.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DogService } from '../services/dog.service';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { QueryModalHealthConditionComponent } from '../query-modal/query-modal-health-condition.component';
import { QueryModalDeathComponent } from '../query-modal-death/query-modal-death.component';
import { EditModes } from '../models/forms.model';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {
  @Input() editMode = EditModes.notEditable
  @Input() summary: boolean = false;
  @Input() formId: number = -1;
  @Input() hasHealthCondition: boolean = false;
  @Input() hasCauseOfDeath: boolean = false;
  @Output() itemApprovedOrDenied = new EventEmitter();

  _dog: Dog | undefined;
  app_num = 0;
  numberOfHealths = 0;
  numberOfPublicHealths = 0;
  healthCategories: HealthCategoriesResponse = {};
  healthCategoryNames: (string | undefined)[] | undefined = [];
  healthCategoryOptionNames: (string | undefined)[] | undefined = [];
  healthCategoryDeathOptionNames: (string | undefined)[] | undefined = [];
  dateOfDeathModel: NgbDateStruct | undefined;
  submitted = false;
  healthEditForm: FormGroup;
  @Input() pendingChanges: PendingHealthChanges[] = [];
  isSuperAdmin = false;
  showSubmit = false;
  showApprove = false;
  showDone = false;
  healthConditionString = '';

  @Input('dog')
  set in(val: Dog) {
    this._dog = val;
    this.dogCoatColor = this._dog?.coat_color?.coat_color_description ?? 'Unknown';
    const datenumeric = this._dog?.date_of_birth ?? '1970-01-01T00:00:00.000Z';
    const dateDate = DateTime.fromISO(datenumeric);
    this.dogDOB = dateDate.toUTC().toISODate() ?? 'Unknown';
    const datenumeric2 = this._dog?.date_of_death ?? '';
    if (datenumeric2 !== '') {
      const dateDate = DateTime.fromISO(datenumeric2);
      this.dogDOD = dateDate.toUTC().toISODate() ?? '';
    }
    this.dogGender = this._dog?.sex == 'M' ? 'Male' : 'Female' ?? 'Unknown';
    let dogId = this._dog?.dog_id;
    if (dogId) {
      this.dogService.getHealthChanges(dogId, this.formId, false)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.pendingChanges = res.healthConditions;
            this.updateFormFields();
          });
        });
    }
  }

  _healths: HealthResponse = {health: [{}], conditions: [{}], hasCauseOfDeath: false, conditionsCount: 0};

  @Input('healths')
  set in2(val: HealthResponse) {
    this._healths = val;
    this.numberOfHealths = this._healths.health?.length ?? 0;
    this.numberOfPublicHealths = this._healths.conditions?.length ?? 0;
    this._healths.health?.forEach(health => {
      if (this.app_num === 0) {
        this.app_num = health.app_num ?? 0;
      }
      if (health.test_date) {
        const datenumeric = health.test_date;
        const dateDate = DateTime.fromISO(datenumeric);
        health.test_date = dateDate.toUTC().toISODate() ?? 'Unknown';
      } else {
        health.test_date = 'Unknown';
      }
      if (health.report_date) {
        const datenumeric = health.report_date;
        const dateDate = DateTime.fromISO(datenumeric);
        health.report_date = dateDate.toUTC().toISODate() ?? 'Unknown';
      } else {
        health.report_date = 'Unknown';
      }

    })
  }

  hasPictures = false;
  dogPictures: DogImage[] = [];
  dogCoatColor = 'Unknown';
  dogDOB = 'Unknown';
  dogDOD = '';
  dogGender = 'Unknown';
  @Output() tabSelected = new EventEmitter();
  sub: Subscription | undefined;

  constructor(private tokenService: TokenStorageService,
              private fb: FormBuilder,
              private ngZone: NgZone,
              private dogService: DogService,
              private dialog: MatDialog,
              private router: Router) {
    this.healthEditForm = this.fb.group({
      healthConditions: [''],
      causeOfDeath: ['']
    });
   }

  ngOnInit(): void {
    const tabEl = document.querySelector('a[data-bs-target="#v-pills-health"]')
    tabEl?.addEventListener('shown.bs.tab', event => {
      this.tabSelected.emit('Health');
    });
    this.sub = this.dogService.getHealthCategories()
      .subscribe((res) => {
        this.healthCategories = res;
        this.healthCategoryNames = this.healthCategories.health?.map((acat) => acat.title);
      });
    this.isSuperAdmin = this.tokenService.userHasRole('superAdmin');

  }

  getHealthClass() {
    if (this.summary) {
      return "table table-sm summary-table table-bordered table-striped table-hover";
    } else {
      return "table table-bordered table-striped table-hover";
    }
  }

  addRecord() {

  }

  async addHCRecord() {
    const dOptions = await this.openQueryModal();
    console.log('Got option name: ' + dOptions.cause);
    const causeCategory = this.healthCategories.health?.filter((aCat) => aCat.title === dOptions.category);
    if (causeCategory && causeCategory.length > 0) {
      const firstCategory = causeCategory[0];
      const causeOption = firstCategory.options?.filter((anOption) => anOption.title === dOptions.cause);
      if (causeOption && causeOption.length > 0) {
        if (this._dog?.dog_id != null) {

        }
      }
    }}

  async openQueryModal(): Promise<any> {
    const dialogRef = this.dialog.open(QueryModalHealthConditionComponent, {
      width: '400px'
    });
    const instance = dialogRef.componentInstance;
    instance.prompt = 'Enter Health Condition Info:';
    instance.healthCategories = this.healthCategories;
    return await dialogRef.afterClosed().toPromise();
  }

  // This method is not called because the admin doesn't get to set any health or death info for the dog
  // This may be resurrected when adding those features to the admin site
  async addDRecord() {
    const dOptions = await this.openDeathQueryModal();
    console.log('Got cause name: ' + dOptions.cause + ' on date: ' + dOptions.dateOfDeath.year + '-' + dOptions.dateOfDeath.month + '-' + dOptions.dateOfDeath.day);
    const causeCategory = this.healthCategories.health?.filter((aCat) => aCat.title === dOptions.category);
    if (causeCategory && causeCategory.length > 0) {
      const firstCategory = causeCategory[0];
      const causeOption = firstCategory.options?.filter((anOption) => anOption.title === dOptions.cause);
      if (causeOption && causeOption.length > 0) {
        const dateOfDeath = DateTime.fromISO(dOptions.dateOfDeath.year + '-' + dOptions.dateOfDeath.month.toString().padStart(2, '0') + '-' + dOptions.dateOfDeath.day.toString().padStart(2, '0'));
        const bigIntDate = dateOfDeath.toMillis();
        if (this._dog?.dog_id != null) {
          this.dogService.addHealthCategory(this._dog?.dog_id, {
            causeId: causeOption[0].id,
            isDeath: true,
            dateOfDeath: bigIntDate,
          })
            .subscribe((res) => {
              console.log(res);
            })
        }
      }
    }
  }

  async openDeathQueryModal(): Promise<any> {
    const dialogRef = this.dialog.open(QueryModalDeathComponent, {
      width: '400px',
      height: '600px'
    });
    const instance = dialogRef.componentInstance;
    instance.prompt = 'Enter Cause and Date of Death:';
    instance.healthCategories = this.healthCategories;
    return await dialogRef.afterClosed().toPromise();
  }

  addDeathRecord() {

  }

  editHealth(aHealth: Health) {

  }

  gotoOfa() {
    window.open("https://ofa.org/advanced-search/?appnum=" + this.app_num);
  }

  getCauseOfDeathValue() {
    if (this._healths.hasCauseOfDeath) {
      return "Is Already Entered";
    } else {
    return "Unknown";
    }
  }

  getIsCauseOfDeath(hc: HealthCondition) {
    if (hc.isCauseOfDeath) {
      return " - Cause of Death";
    } else {
      return "";
    }
  }
  getEditModeClass() {
    if (this.editMode === EditModes.editable ||this.editMode === EditModes.waitingForApproval) {
      return "col-md-12 mx-auto"
    } else {
      return "col-md-6 mx-auto"
    }
  }

  submitHealthConditionField() {
    const dogId = this._dog?.dog_id;
    if (dogId && (this.formId != -1)) {
      this.dogService.addPendingHealthCategory(dogId, {
        formId: this.formId
      })
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.dogService.getHealthChanges(dogId, this.formId, false)
              .subscribe((res) => {
                this.ngZone.run(() => {
                  this.pendingChanges = res.healthConditions;
                  this.updateFormFields();
                  this.itemApprovedOrDenied.emit('changed');
                });
              });
          });
        })
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  approveOrDenyField(isApproved: boolean) {
    const aPending = this.pendingChanges.filter((aChange) => {
      return (aChange.pending);
    });
    if (aPending.length === 1) {
      const toApprove = aPending[0];
      const dogId = this._dog?.dog_id;
      if (dogId) {
        this.dogService.approvePendingHealthChange(dogId, toApprove.id, {
          formId: this.formId,
          isPending: !isApproved
        })
          .subscribe((res) => {
            this.ngZone.run(() => {
              this.dogService.getHealthChanges(dogId, this.formId, false)
                .subscribe((res) => {
                  this.ngZone.run(() => {
                    this.pendingChanges = res.healthConditions;
                    this.updateFormFields();
                    this.itemApprovedOrDenied.emit('changed');
                  });
                });
            });
          });
      }
    }
  }

  private updateFormFields() {
    this.showSubmit = true;
    this.showDone = false;
    this.showApprove = false;
    this.healthConditionString = 'Requested addition of a health condition.';
    if (this.hasCauseOfDeath) {
      this.healthConditionString = 'Requested addition of a health condition as the Cause of Death.';
    }

    if (this.pendingChanges.length > 0) {
      this.pendingChanges.forEach(aChange => {
        if (this.hasCauseOfDeath) {
          // check if it HAS been submitted yet
          if (aChange.isCauseOfDeath) {
            if (aChange.pending) {
              this.showApprove = true;
              this.showSubmit = false;
            } else {
              this.showDone = true;
              this.showSubmit = false;
            }

          }
        }
          // check if it HAS been submitted yet
        if (!aChange.isCauseOfDeath) {
          if (aChange.pending) {
            this.showApprove = true;
            this.showSubmit = false;
          } else {
            this.showDone = true;
            this.showSubmit = false;
          }
        }
      });
    }
  }

  protected readonly EditModes = EditModes;
}
